import { type ReactNode, createContext, useContext, useState } from 'react';
import { type StoreApi, useStore } from 'zustand';

const createZustandContext = <TInitial, TState>(getStore: (initial: TInitial) => StoreApi<TState>) => {
	const Context = createContext(null as any as StoreApi<TState>);

	const Provider = (props: { children?: ReactNode; initialValue: TInitial }) => {
		const [store] = useState(() => getStore(props.initialValue));

		return <Context.Provider value={store}>{props.children}</Context.Provider>;
	};

	const useCustomStore = <T, U>(selector: (state: TState) => U): U => {
		const store = useContext(Context);

		if (!store) {
			throw new Error('Missing provider');
		}

		return useStore(store, selector);
	};

	return {
		useContext: () => useContext(Context),
		Context,
		Provider,
		useStore: useCustomStore,
	};
};

export default createZustandContext;
