import type { Question } from '@features/api/client';
import ProblemContentBlock from '@features/shared/blocks/ProblemContentBlock';
import { richtext } from '@features/shared/utils/richtext';

type QuestionTextProps = {
	question: Question;
};

function QuestionText({ question }: QuestionTextProps) {
	return (
		<>
			{question.text && richtext(question.text, 'prose')}
			{question.blocks.map((block) => (
				<ProblemContentBlock block={block} key={block.id} />
			))}
		</>
	);
}

export default QuestionText;
