import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswer from '../QuestionAnswer/QuestionAnswer';

type QuestionMultiLineProps = { textAnswers: Array<QuestionAnswerType>; questionId: number };

function QuestionMultiLine({ textAnswers }: QuestionMultiLineProps) {
	return textAnswers.map((answer, index) => (
		<QuestionAnswer key={answer.id} answer={answer} index={index} />
	));
}

export default QuestionMultiLine;
