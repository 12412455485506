import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswer from '../QuestionAnswer/QuestionAnswer';

type QuestionSingleLineProps = { textAnswers: Array<QuestionAnswerType>; questionId: number };

function QuestionSingleLine({ textAnswers }: QuestionSingleLineProps) {
	return textAnswers.map((answer, index) => (
		<QuestionAnswer key={answer.id} answer={answer} index={index} />
	));
}

export default QuestionSingleLine;
