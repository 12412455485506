'use client';

import type { Question } from '@features/api/client';
import { richtext } from '@features/shared/utils/richtext';
import QuestionAnswerInput from './QuestionAnswerInput';

type QuestionFillInTheBlankProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionFillInTheBlank({ questions, leadText }: QuestionFillInTheBlankProps) {
	return (
		<div className="prose !max-w-[75ch] [&_.question-text]:!inline [&_.question-text_p]:!inline">
			{leadText && richtext(leadText + ' ', 'question-text', 'span')}
			{questions.map((question) => {
				return (
					<>
						{question.text && richtext(question.text, 'question-text', 'span')}
						<QuestionAnswerInput answers={question.ordered_answers} />
						{question.postanswer_text &&
							richtext(question.postanswer_text + ' ', 'question-text', 'span')}
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</>
				);
			})}
		</div>
	);
}

export default QuestionFillInTheBlank;
