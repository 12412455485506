import { Input, theme, Button, Tooltip } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';
import { cn } from '@features/shared/utils/utils';
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import type { QuestionAnswer } from '@features/api/client';
import stripHtmlTags from '@features/webshop/shared/domain/stripHtmlTags';
import { unique } from 'remeda';

type QuestionAnswerInputProps = {
	answers: Array<QuestionAnswer>;
};

function QuestionAnswerInput({ answers }: QuestionAnswerInputProps) {
	const {
		token: { colorSuccess, colorError },
	} = theme.useToken();

	const [inputValue, setInputValue] = useState('');
	const [areAnswersVisible, setAreAnswersVisible] = useState(false);

	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);

	const acceptedAnswers = unique(
		answers
			.map((answer) => [
				stripHtmlTags({ text: answer.display_text }),
				...answer.accepted_answers.map((acceptedAnswer) =>
					stripHtmlTags({ text: acceptedAnswer.text })
				),
			])
			.flat()
	);

	const isCorrect = acceptedAnswers.includes(inputValue);
	const statusColor = isCorrect ? colorSuccess : colorError;
	const suffixIcon = isCorrect ? (
		<CheckCircleOutlined style={{ color: statusColor }} />
	) : (
		<CloseCircleOutlined style={{ color: statusColor }} />
	);

	const shouldShowAnswers = areAnswersBeingChecked && areAnswersVisible && !isCorrect;

	return (
		<div
			className={cn(
				'question-text relative',
				areAnswersBeingChecked && '[&_.ant-input]:!border-inherit'
			)}>
			<Input
				value={inputValue}
				status={areAnswersBeingChecked && !isCorrect ? 'error' : undefined}
				style={{
					width: 'fit-content',
					minWidth: '184px',
					color: areAnswersBeingChecked ? statusColor : undefined,
					borderColor: areAnswersBeingChecked ? statusColor : undefined,
				}}
				className={cn('!mx-2 !my-1', areAnswersBeingChecked && !isCorrect && '!mr-0')}
				onChange={(e) => {
					setAreAnswersVisible(false);
					setAreAnswersBeingChecked(false);
					setInputValue(e.target.value);
				}}
				suffix={areAnswersBeingChecked ? suffixIcon : null}
			/>
			{areAnswersBeingChecked && !isCorrect && (
				<Tooltip title={'Prikaži odgovore'}>
					<Button
						size="small"
						type="link"
						onClick={() => setAreAnswersVisible(!areAnswersVisible)}
						style={{ marginRight: '8px' }}
						className={cn(shouldShowAnswers && 'mb-8')}>
						{areAnswersVisible ? <EyeInvisibleOutlined /> : <EyeOutlined />}
					</Button>
				</Tooltip>
			)}
			{shouldShowAnswers && (
				<span className="absolute bottom-0 left-0 right-0 translate-y-[150%] px-6 text-left text-sm text-gray-500">
					{acceptedAnswers.join(', ')}
				</span>
			)}
		</div>
	);
}

export default QuestionAnswerInput;
