import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswer from '@features/frontoffice/problems/ui/QuestionAnswer/QuestionAnswer';
import { Flex, Radio } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';

type QuestionSingleChoiceProps = {
	answerChoices: Array<QuestionAnswerType>;
	questionId: number;
};

function QuestionSingleChoice({ answerChoices, questionId }: QuestionSingleChoiceProps) {
	const selectedAnswerChoices = useQuestionGroupStore((state) => state.selectedAnswerChoices);
	const setSelectedAnswerChoices = useQuestionGroupStore((state) => state.setSelectedAnswerChoices);

	return (
		<Radio.Group
			size="large"
			onChange={(e) =>
				setSelectedAnswerChoices([
					...selectedAnswerChoices.filter((choice) => choice.questionId !== questionId),
					{ questionId: questionId, answerId: e.target.value },
				])
			}>
			<Flex gap={16} vertical>
				{answerChoices.map((answer, index) => (
					<QuestionAnswer key={answer.id} answer={answer} index={index} />
				))}
			</Flex>
		</Radio.Group>
	);
}

export default QuestionSingleChoice;
