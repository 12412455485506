import type { QuestionAnswerBlock } from '@features/api/client';
import ProblemContentBlock from '@features/shared/blocks/ProblemContentBlock';
import { Flex } from 'antd';

type AnswerChoiceBlockProps = {
	block: QuestionAnswerBlock;
};

function AnswerChoiceBlock({ block }: AnswerChoiceBlockProps) {
	return <ProblemContentBlock block={block} key={block.id} />;
}

type AnswerChoiceBlocksProps = {
	blocks: Array<QuestionAnswerBlock>;
};

function AnswerChoiceBlocks({ blocks }: AnswerChoiceBlocksProps) {
	return blocks?.map((block) => (
		<Flex gap="0 .25rem" align="center" wrap="wrap" key={block.id}>
			<AnswerChoiceBlock block={block} />
		</Flex>
	));
}

export default AnswerChoiceBlocks;
