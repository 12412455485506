import type { QuestionAnswer as QuestionAnswerType } from '@features/api/client';
import QuestionAnswer from '@features/frontoffice/problems/ui/QuestionAnswer/QuestionAnswer';
import { Checkbox, Flex } from 'antd';
import { useQuestionGroupStore } from './QuestionGroupStore';

type QuestionMultipleChoiceProps = {
	answerChoices: QuestionAnswerType[];
	questionId: number;
};

function QuestionMultipleChoice({ answerChoices, questionId }: QuestionMultipleChoiceProps) {
	const setSelectedAnswerChoices = useQuestionGroupStore((state) => state.setSelectedAnswerChoices);

	return (
		<Checkbox.Group
			onChange={(checkedValues) =>
				setSelectedAnswerChoices(checkedValues.map((value) => ({ questionId, answerId: value })))
			}>
			<Flex gap={16} vertical>
				{answerChoices.map((answer, index) => (
					<QuestionAnswer key={answer.id} answer={answer} index={index} />
				))}
			</Flex>
		</Checkbox.Group>
	);
}

export default QuestionMultipleChoice;
