import createZustandContext from '@features/shared/providers/createZustandContext';
import { createStore } from 'zustand';

type SelectedAnswerChoice = { questionId: number; answerId: number };

type QuestionGroupStoreState = {
	areAnswersBeingChecked: boolean;
	selectedAnswerChoices: SelectedAnswerChoice[];
	setAreAnswersBeingChecked: (value: boolean) => void;
	setSelectedAnswerChoices: (value: SelectedAnswerChoice[]) => void;
};

type InitialState = {
	areAnswersBeingChecked: boolean;
	selectedAnswerChoices: SelectedAnswerChoice[];
};

const QuestionGroupStore = createZustandContext<InitialState, QuestionGroupStoreState>((initialState) => {
	return createStore<QuestionGroupStoreState>()((set) => ({
		areAnswersBeingChecked: initialState.areAnswersBeingChecked,
		selectedAnswerChoices: initialState.selectedAnswerChoices,
		setSelectedAnswerChoices: (value) =>
			set((state) => ({
				selectedAnswerChoices: value,
			})),
		setAreAnswersBeingChecked: (value) =>
			set((state) => ({
				areAnswersBeingChecked: value,
			})),
	}));
});

export default QuestionGroupStore;

export const useQuestionGroupStore = QuestionGroupStore.useStore;
