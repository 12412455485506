'use client';
import { Button, Space } from 'antd';
import { IconRefresh } from '@tabler/icons-react';
import { useQuestionGroupStore } from './QuestionGroupStore';

function CheckAnswersButton() {
	const areAnswersBeingChecked = useQuestionGroupStore((state) => state.areAnswersBeingChecked);
	const setAreAnswersBeingChecked = useQuestionGroupStore((state) => state.setAreAnswersBeingChecked);

	return (
		<Space size={'small'}>
			<Button type="primary" onClick={() => setAreAnswersBeingChecked(true)}>
				Provjeri odgovore
			</Button>
			{areAnswersBeingChecked && (
				<Button type="text" size="small" onClick={() => setAreAnswersBeingChecked(false)}>
					<IconRefresh />
				</Button>
			)}
		</Space>
	);
}

export default CheckAnswersButton;
