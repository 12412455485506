import type { MultipleSizeImageBlockValue } from '@features/api/client';
import CloudinaryImage from '../ui/CloudinaryImage';
import { cn } from '@features/shared/utils/utils';

type MultipleSizeImageBlockProps = {
	block: MultipleSizeImageBlockValue;
	className?: string;
};

function MultipleSizeImageBlock({ block, className }: MultipleSizeImageBlockProps) {
	if (!block.image.url) return null;

	return (
		<>
			<CloudinaryImage
				className={cn('hidden rounded-2xl md:block', className)}
				alt={block.image.title}
				width={block.image.width}
				height={block.image.height}
				src={block.image.url}
			/>
			{block.image_mobile.url && (
				<CloudinaryImage
					className={cn('rounded-2xl md:hidden', className)}
					alt={block.image_mobile.title}
					width={block.image_mobile.width}
					height={block.image_mobile.height}
					src={block.image_mobile.url}
				/>
			)}
		</>
	);
}

export default MultipleSizeImageBlock;
