'use client';
import QuestionGroupStore from './QuestionGroupStore';
import { type QuestionGroup as QuestionGroupType } from '../../domain/normalizeQuestions';
import CheckAnswersButton from './CheckAnswersButton';
import QuestionAutocompleteFromSamePool from './QuestionAutocompleteFromSamePool';
import { Flex } from 'antd';
import QuestionAutocomplete from './QuestionAutocomplete';
import QuestionSingleChoice from './QuestionSingleChoice';
import QuestionMultipleChoice from './QuestionMultipleChoice';
import QuestionText from './QuestionText';
import QuestionSingleLine from './QuestionSingleLine';
import QuestionMultiLine from './QuestionMultiLine';
import QuestionFillInTheBlank from './QuestionFillInTheBlank';
import { richtext } from '@features/shared/utils/richtext';

type QuestionGroupProps = {
	questionGroup: QuestionGroupType;
	leadText?: string | null | undefined;
};

function QuestionGroup({ questionGroup, leadText }: QuestionGroupProps) {
	let questionMarkup = null;
	switch (questionGroup.type) {
		case 'autocomplete_from_same_pool':
			questionMarkup = (
				<QuestionAutocompleteFromSamePool questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		case 'autocomplete':
			questionMarkup = <QuestionAutocomplete questions={questionGroup.questions} leadText={leadText} />;
			break;

		case 'single_choice':
			questionMarkup = (
				<Flex gap={40} vertical>
					{leadText && richtext(leadText + ' ', 'question-text prose', 'span')}
					{questionGroup.questions.map((question) => (
						<Flex gap={16} vertical key={question.id}>
							<QuestionText question={question} />
							<QuestionSingleChoice
								answerChoices={question.ordered_answers}
								questionId={question.id}
							/>
						</Flex>
					))}
				</Flex>
			);
			break;
		case 'multiple_choice':
			questionMarkup = (
				<Flex gap={40} vertical>
					{leadText && richtext(leadText + ' ', 'question-text prose', 'span')}
					{questionGroup.questions.map((question) => (
						<Flex gap={16} vertical key={question.id}>
							<QuestionText question={question} />
							<QuestionMultipleChoice
								answerChoices={question.ordered_answers}
								questionId={question.id}
							/>
						</Flex>
					))}
				</Flex>
			);
			break;

		case 'multiline':
			questionMarkup = (
				<Flex gap={40} vertical>
					{leadText && richtext(leadText + ' ', 'question-text prose', 'span')}
					{questionGroup.questions.map((question) => (
						<Flex gap={16} vertical key={question.id}>
							<QuestionText question={question} />
							<QuestionMultiLine
								textAnswers={question.ordered_answers}
								questionId={question.id}
							/>
						</Flex>
					))}
				</Flex>
			);
			break;

		case 'single_line':
			questionMarkup = (
				<Flex gap={40} vertical>
					{leadText && richtext(leadText + ' ', 'question-text prose', 'span')}
					{questionGroup.questions.map((question) => (
						<Flex gap={16} vertical key={question.id}>
							<QuestionText question={question} />
							<QuestionSingleLine
								textAnswers={question.ordered_answers}
								questionId={question.id}
							/>
						</Flex>
					))}
				</Flex>
			);
			break;

		case 'fill_in_the_blank':
			questionMarkup = (
				<QuestionFillInTheBlank questions={questionGroup.questions} leadText={leadText} />
			);
			break;
		default:
			break;
	}

	return (
		<QuestionGroupStore.Provider
			initialValue={{
				areAnswersBeingChecked: false,
				selectedAnswerChoices: [],
			}}>
			<Flex vertical gap={48}>
				{questionMarkup}
				<CheckAnswersButton />
			</Flex>
		</QuestionGroupStore.Provider>
	);
}

export default QuestionGroup;
