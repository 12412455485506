import DOMPurify from 'isomorphic-dompurify';
import { cn } from './utils';
import RoughNotation from '@features/webshop/landing-pages/ui/sections/Pitchtext/RoughNotation';

export function richtext(
	text: string,
	className?: string,
	as: 'h1' | 'h2' | 'h3' | 'h4' | 'div' | 'span' = 'div',
	key?: string | number
) {
	const html = DOMPurify.sanitize(text, {
		USE_PROFILES: { html: true },
		ADD_TAGS: [
			'highlight-orange',
			'color-orange',
			'highlight-blue',
			'color-blue',
			'highlight-red',
			'color-red',
			'highlight-green',
			'color-green',
			'color-yellow',
			'img',
			'a',
		],
	});

	const Element = as;

	const hasAnimation = text.includes('rough-notation-animate') || text.includes('hand-drawn-underline');

	const elementMarkup = (
		<Element
			className={cn(as === 'span' && 'inline-block', className)}
			suppressHydrationWarning
			dangerouslySetInnerHTML={{ __html: html }}
			key={key}
		/>
	);

	if (!hasAnimation) return elementMarkup;
	return <RoughNotation>{elementMarkup}</RoughNotation>;
}
