'use client';

import { useEffect } from 'react';

interface customWindow extends Window {
	MathJax: {
		tex: {
			inlineMath: { '[+]': string[][] };
		};
		svg: { fontCache: string };
		startup: {
			readonly ready: () => void;
			readonly defaultReady: () => void;
			promise?: Promise<void>;
		};
		readonly typesetClear: (_data: HTMLElement[]) => void;
		readonly typesetPromise: (_data?: HTMLElement[] | null) => Promise<void>;
	};
}

declare const window: customWindow;

function initializeMathJax() {
	if (document.querySelector('#MathJax-script')) return;

	if (!window.MathJax) {
		window.MathJax = {
			tex: {
				inlineMath: {
					'[+]': [
						['$', '$'],
						['\\(', '\\)'],
					],
				},
			},
			svg: { fontCache: 'local' },
			// @ts-ignore
			startup: {
				ready: () => {
					console.log('MathJax is loaded, but not yet initialized');
					if (window.MathJax.startup.defaultReady) window.MathJax.startup.defaultReady();
					console.log('MathJax is initialized, and the initial typeset is queued');
				},
			},
		};
	}

	const script = document.createElement('script');
	script.setAttribute('id', 'MathJax-script');
	script.setAttribute('async', '');
	script.setAttribute('defer', '');
	script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
	document.body.appendChild(script);
}

export function typeset(DOMelement?: HTMLElement | null | undefined) {
	const DOMarray = DOMelement ? [DOMelement] : [document.body];

	if (!window.MathJax?.startup?.promise || !window.MathJax.typesetPromise) {
		console.warn('MathJax not loaded');
		return;
	}

	window.MathJax.typesetClear(DOMarray);
	window.MathJax.startup.promise = window.MathJax.startup.promise
		.then(() => window.MathJax.typesetPromise(DOMarray))
		.catch((err: Error) => console.warn(`Typeset failed: ${err.message}`));

	return window.MathJax.startup.promise;
}

export function useMathJaxTypeset(DOMelement?: HTMLElement | null | undefined) {
	useEffect(() => {
		if (window === undefined) return;

		initializeMathJax();
		typeset(DOMelement);
	});
}

export function MathJaxProvider({
	children,
	element = null,
}: {
	children: React.ReactNode;
	element?: HTMLElement | null;
}) {
	useMathJaxTypeset(element);
	return children;
}
