import type { QuestionAnswerBlock } from '@features/api/client';
import ProblemContentBlock from '@features/shared/blocks/ProblemContentBlock';

type AnswerTextBlockProps = {
	block: QuestionAnswerBlock;
};

function AnswerTextBlock({ block }: AnswerTextBlockProps) {
	return <ProblemContentBlock block={block} key={block.id} />;
}

type AnswerTextBlocks = {
	blocks: Array<QuestionAnswerBlock>;
};

function AnswerTextBlocks({ blocks }: AnswerTextBlocks) {
	return blocks?.map((block) => <AnswerTextBlock block={block} key={block.id} />);
}

export default AnswerTextBlocks;
