/* eslint-disable no-unused-vars */
import type { QuestionAnswerBlock, QuestionBlock } from '@features/api/client';
import { type ReactNode } from 'react';
import MultipleSizeImageBlock from './MultipleSizeImageBlock';
import { richtext } from '@features/shared/utils/richtext';
import TableBlock from './TableBlock';
import MultipleThemesImageBlock from './MultipleThemesImageBlock';

type QuestionContentBlock = QuestionBlock & QuestionAnswerBlock;
type QuestionContentBlockType = QuestionBlock['type'] & QuestionAnswerBlock['type'];
type ContentBlockMap = {
	[K in QuestionContentBlockType]: (block: Extract<QuestionContentBlock, { type: K }>) => ReactNode;
};

const contentBlockMap = {
	dual_size_image: (block) => <MultipleSizeImageBlock block={block.value} />,
	dual_theme_image: (block) => <MultipleThemesImageBlock block={block.value} />,
	rich_text: (block) => richtext(block.value, 'prose', 'span'),
	table: (block) => <TableBlock block={block.value} />,
} satisfies ContentBlockMap;

function ProblemContentBlock<T extends QuestionContentBlock>({ block }: { block: T }) {
	const renderBlock = contentBlockMap[block.type] as (block: T) => ReactNode;

	if (!renderBlock) return null;

	return renderBlock(block);
}

export default ProblemContentBlock;
