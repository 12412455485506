export const choiceLabels = [
	'A.',
	'B.',
	'C.',
	'D.',
	'E.',
	'F.',
	'G.',
	'I.',
	'J.',
	'K.',
	'L.',
	'M.',
	'N.',
	'O.',
	'P.',
	'R.',
	'S.',
	'T.',
	'U.',
	'V.',
	'Z.',
];
