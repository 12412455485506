'use client';
import type { Question } from '@features/api/client';
import { richtext } from '@features/shared/utils/richtext';
import { normalizeSamePoolAnswers } from '../../domain/normalizeQuestions';
import QuestionAnswerSelect from './QuestionAnswerSelect';

type QuestionAutocompleteFromSamePoolProps = {
	questions: Array<Question>;
	leadText?: string | null | undefined;
};

function QuestionAutocompleteFromSamePool({ questions, leadText }: QuestionAutocompleteFromSamePoolProps) {
	const { uniqueAnswers, initalAnswers } = normalizeSamePoolAnswers(questions);

	return (
		<div className="prose !max-w-[75ch] [&_.question-text]:!inline [&_.question-text_p]:!inline">
			{leadText && richtext(leadText + ' ', 'question-text', 'span')}
			{questions.map((question) => {
				return (
					<>
						{question.text && richtext(question.text, 'question-text', 'span')}
						<QuestionAnswerSelect
							question={question}
							uniqueAnswers={uniqueAnswers}
							initalAnswers={initalAnswers}
						/>
						{question.postanswer_text &&
							richtext(question.postanswer_text + ' ', 'question-text', 'span')}
						{question.question_display_type === 'vertical' && (
							<>
								<br />
								<br />
							</>
						)}
					</>
				);
			})}
		</div>
	);
}

export default QuestionAutocompleteFromSamePool;
